html, body{
  max-width: 100%;
  overflow-x: hidden;
}
@font-face {
  font-family: chinese_font_1;
  src: url('./chinese_font_1.ttf');
}
.App{
  /* background:url("pics/background.jpg");
  background-size:cover;
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-position: center; */
  font-family: chinese_font_1;
  width: 100vw;
}
.background-img{
  width:100vw;
  height:100vh;
  background-image:url('pics/background.jpg'); 
  background-size:cover; 
  background-position: 10% bottom;
  position: fixed; 
  z-index:-1;
}
.ui{
  height: 10vh;
  width: 100vw;
  position: fixed;
  transition: height 0.5s ease-in;
  z-index: +1;
}
.ui-show{
  height: 100vh;
}
header{
  height: 10vh;
  width: 100%;
  background-color:rgba(0,0,0,0.7);
  transition: background-color 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.navigation{
  width: 100%;
  height: 10vh;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.navs{
  text-decoration: none;
  width: 25%;
  color:white;
  font-size: 15px;
  transition: background 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.navs:hover{
  background: rgba(187, 187, 187, 0.6);
}
.fas{
  padding:0 8px;
}
.fab{
  padding:0 8px;
}
.resume{
  height: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  overflow: scroll;
  transition: height 0.5s;
}
.resume-btn-group{
  position: absolute;
  top: 15vh;
  right: 10vw;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.close-btn{
  height: 50px;
  width: 50px;
  font-size: 13px;
  color: white;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  transition: background-color 0.5s;
  cursor: pointer;
}
.close-btn .fas{
  font-size: 30px;
}
.close-btn:hover{
  background-color: rgba(0,0,0,0.7);
}
.download-btn{
  height: 50px;
  width: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  transition: background-color 0.5s;
  cursor: pointer;
}
.download-btn .fas{
  font-size: 30px;
}
.download-btn a{
  text-decoration: none;
  font-size: 13px;
  color: white;
}
.download-btn:hover{
  background-color: rgba(0,0,0,0.7);
}
.btn-show{
  display: flex;
}
.resume-content{
  height: auto;
  width: 90%;
  display: block;
  margin: 2vh auto;
}
.resume-show{
  height: 75%;
} 
.info{
  height: 15%;
  width: 100%;
  color:white;
  background-color:rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
}
.info-title{
  width: 80vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.resume-btn{
  font-size: 13px;
  /* font-family: arial; */
  height: 27px;
  width: 70px;
  margin: 0 5px;
  display:flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.3);
  -webkit-transition: background 0.5s, color 0.5s;
  cursor: pointer;
}
.resume-btn:hover{
  background: rgba(255,255,255,1);
  color: rgba(0,0,0,0.7);
}
.info-list{
  width: fit-content;
  display: flex;
  justify-content: center;
}
.info-single{
  height: 30px;
  width: 30px;
  margin: 0 15px;
}
.language-btn{
  height: 30px;
  width: fit-content;
  font-size: 24px;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 1);
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  transition: background-color 0.5s, color 0.5s;
}
.language-btn:hover{
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgba(0,0,0,0.3);
}
.info-single a{
  color: white;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.envelope i{
  font-size: 26px;
}
.info-single:hover{
  animation: translateY 0.5s forwards;
}
/* header{
  height:6vh;
  min-height:50px;
  position:fixed;
  width:100%;
  background-color:rgba(0,0,0,0.7);
  transition: background-color 0.5s;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999999
}
.logo{
  margin: 1vw;
  display: none;
}
*/
h1{
  display:inline-block;
  color:white;
} 
.home{
  height: 100vh;
  min-height: 740px;
  background-image: url('pics/chalk_cover.png'), url('pics/chalkboard.jpg');
  background-position: -25vw 20vh, top left;
  background-size: auto 70%, 100% 100%;
  background-repeat: no-repeat;
  background-color: rgb(31, 31, 31);
  font-size:25px;
  color: white;
}
.home-box-spacer{
  height: 15vh;
  min-height: 95px;
}
.home-box{
  margin-right: 5vw;
  min-height: 300px;
  float: right;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.word-box{
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  /* top: 15vh;
  left: 10vw;
  position: absolute; */
}
#latest-project{
  margin-top: 20px;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-image: url('pics/chalkborder.jpg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
#latest-name{
  height: 50px;
  width: 250px;
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: rgba(0,0,0,0.6);
  color: white;
  background: rgba(0,0,0,0.6);
  color: white;
}
#latest-pic{
  height: 180px;
  width: 220px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.7);
  cursor: pointer;
  box-sizing: border-box;
  position: unset;
}
.home h2{
  font-size: 45px;
}
.spacer{
  width: 100%;
  height: 11vh;
  min-height: 50px;
}
.work{
  padding:20px 0;
  background: url('pics/chalkboard.jpg');
  background-size: 100% 100%;
  font-size:25px;
  color: white;
  /* margin: 40px 0 0 0; */
}
.work h2{
  text-align: center;
  font-size: 25px;
  margin: 0 0 2vh 0;
}
.work i{
  color: white;
}
.project-list{
  width:80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
}
.project{
  height: 330px;
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */
  margin: 1px;
  box-sizing: border-box;
  background-image: url('pics/chalkborder.jpg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.project-name{
  background: rgba(0,0,0,0.3);
  height: 50px;
  width: 250px;
  text-align: center;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  /* border: 1px solid white; */
}
.project-pic{
  height: 200px;
  width: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.7);
  cursor: pointer;
  box-sizing: border-box;
  /* border-left: 1px solid white;
  border-right: 1px solid white; */
}
.project-button-list{
  display: flex;
  flex-direction: row;
  width: 250px;
  background: rgba(0,0,0,0.3);
  transition: background-color 0.5s;
  box-sizing: border-box;
  /* border: 1px solid white; */
}
.project-button{
  height: 30px;
  width: 150px;
  /* background-color: grey; */
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.5s;
  /* border: 1px solid white; */
  box-sizing: border-box;
}
.project-button:hover{
  background: rgba(187, 187, 187, 0.6);
}
.project-button a{
  text-decoration: none;
  color: white;
  width: 100%;
}
.project-pic-17{
  background-image: url('pics/space-exploration.jpg');
}
.project-pic-16{
  background-image: url('pics/we_bubble_two.jpg');
}
.project-pic-15{
  background-image: url('pics/escape_the_witch\'s_grotto.jpg');
}
.project-pic-14{
  background-image: url('pics/staywithme.jpg');
}
.project-pic-13{
  background-image: url('pics/instabot.jpg');
}
.project-pic-12{
  background-image: url('pics/artutorial.jpg');
}
.project-pic-11{
  background-image: url('pics/shell.png');
}
.project-pic-10{
  background-image: url('pics/returntoolympia.png');
}
.project-pic-9{
  background-color: grey;
  background-image: url('pics/lifommunity.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.project-pic-8{
  background-image: url('pics/cuevent.jpg');
}
.project-pic-7{
  background-image: url('pics/geekolympics.jpg');
}
.project-pic-6{
  background-image: url('pics/zombierun.jpg');
}
.project-pic-5{
  background-image: url('pics/ar-pet.jpg');
}
.project-pic-4{
  background-image: url('pics/grandmaster.jpg');
}
.project-pic-2{
  background-image: url('pics/language-studio.jpg');
}
.project-pic-1{
  background-image: url('pics/word-hero.jpg');
}
.tech-used{
  height: 200px;
  width: 250px;
  background:rgba(0,0,0,0.5);
  color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  /* text-align: center; */
  font-size: 15px;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding-left: 10px;
}
.show-tech{
  opacity: 1;
}
.undone-project{
  border: 2px solid grey;
}
.about{
  /* height:105vh; */
  /* min-height: 820px; */
  padding:20px;
  background:rgba(0,0,0,0.1);
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about h2{
  text-align: center;
  margin: 5px;
  font-size: 25px;
}
.paragraph-list{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paragraphs{
  display:flex;
  justify-content: flex-end;
  margin:0 2vw;
  height: 90px;
  width: 80vw;
  max-width: 960px;
  padding: 2vh 0;
  /* border-bottom: 2px solid white; */
  overflow: hidden;
  cursor: pointer;
}
.paragraph-title{
  width: 60vw;
  max-width: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  opacity: 1;
  -webkit-transition: width 0.5s, opacity 0.5s 0.5s;
}
.paragraph-single{
  width: 0vw;
  opacity: 0;
  -webkit-transition: width 0.5s, opacity 0s 0s;
}
.paragraph-single p{
  margin: 0 10px;
}
.paragraphs:hover{
  /* background:rgba(0,0,0,0.3); */
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0));
}
.selection{
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0));    
}
.image{
  height: 95px;
  width: 150px;
  background-size: cover;
  background-position: center;
}
.img-1{
  background-image: url('pics/beijing.jpg');
}
.img-2{
  background-image: url('pics/web_dev.jpg');
}
.img-3{
  background-image: url('pics/unity_paragraph.jpg');
}
.img-4{
  background-image: url('pics/su.jpg');
}
.img-5{
  background-image: url('pics/classroom.jpg');
}
#p5{
  border-top: 2px solid white;
  margin: 2vh 0 0 0;
}
.scroll{
  display: flex;
  width: 80vw;
  max-width: 960px;
  height:fit-content;
  /* height: 0px; */
  justify-content: center;
  align-items: center;
  transition: max-height 0.5s ease-in-out;
  border-bottom: 2px solid white; 
}
.scroll p{
  width: 70vw;
  font-size: 15px;
  margin: 20px;
  transition: opacity 0.5s;
}
.scroll-show{
  /* height: fit-content; */
  max-height:600px;
  /* height: 100px; */
  /* overflow-y: scroll; */
}
.scroll-hide{
  max-height: 0px;
  /* overflow: hidden; */
}
#p5 .paragraph-single{
  display: flex;
  flex-wrap: wrap;
}
/* .scroll-p5{
  display: flex;
  flex-wrap: wrap;
} */
.skill-p{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: opacity 0.7s;
}
.p-show{
  opacity: 1;
  z-index: 1;
}
.p-hide{
  opacity: 0;
  z-index: -1;
}
.skill-single{
  height: 70px;
  width: 70px;
  margin: 5px;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .skill-single:hover{
  background-size: 0%;
} */
.skillbackground-show{
  background-size: auto 0%;
}
.skill-html{
  background-image: url("pics/html.png");
}
.skill-css{
  background-image: url("pics/css.png");
}
.skill-js{
  background-image: url("pics/js.png");
}
.skill-react{
  background-image: url("pics/react.png");
}
.skill-node{
  background-image: url("pics/node.png");
}
.skill-express{
  background-image: url("pics/express.png");
}
.skill-sql{
  background-image: url("pics/sql.png");
}
.skill-ruby{
  background-image: url("pics/ruby.png");
}
.skill-socket{
  background-image: url("pics/socket.png");
}
.skill-java{
  background-image: url("pics/java.png");
}
.skill-csharp{
  background-image: url("pics/csharp.png");
}
.skill-unity{
  background-image: url("pics/unity.png");
}
.skill-android{
  background-image: url("pics/androidstudio.png");
}
.skill-vive{
  background-image: url("pics/vive.png");
}
.skill-vuforia{
  background-image: url("pics/vuforia.png");
}
.skill-git{
  background-image: url("pics/git.png");
}
.skill-name{
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: 0.5s; */
  font-size: 0px;
}
/* .skill-name:hover{
  background: rgba(0,0,0,0.3);
  font-size: 30px;
} */
.skillname-show{
  background: rgba(0,0,0,0.3);
  font-size: 15px;
}
.contact{
  background-color:rgba(0,0,0,0.3);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
.contact h2{
  color: white;
  text-align: center;
  font-size: 25px;
}
.contact-form{
  background-image: url('./pics/chalkboard.jpg');
  margin-bottom: 50px;
  width: 280px;
  height: 400px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 8px rgba(255, 255, 255, 0.8) double;
}
.contact-form input{
  border: none;
  height: 30px;
  width: 94%;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s;
  color: white;
  background-color: rgba(0,0,0,0.2);
}
.contact-form input:hover{
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.contact-form textarea{
  border: none;
  width: 95%;
  height: 250px;
  margin: 25px 0;
  font-size: 20px;
  resize: none;
  transition: background-color 0.3s;
  font-weight: bold;
  color: white;
  box-sizing: border-box;
  padding: 4px;
  background-color: rgba(0,0,0,0.2);
}
.contact-form textarea:hover{
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.contact-form button{
  border: none;
  width: 95%;
  height: 30px;
  background-color: rgba(0,0,0,0.6);
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.contact-form button:hover{
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
@keyframes translateY{
  0%{
      transform: translateY(0px);
  }
  50%{
      transform: translateY(-7px);
  }
  100%{
      transform: translateY(-5px)
  }
}

@media (min-width: 480px){
  header{
      height:12vh;
      min-height:90px;
  }
  .logo{
      display: inline;
  }
  .navigation{
      height: 12vh;
      min-height: 90px;
      width: 80vw;
      max-width: 800px;
  }
  .navs{
      width: 20vw;
      max-width: 200px;
      font-size: 20px;
  }
  .resume{
      top: 20vh;
  }
  .close-btn{
      top: 20vh;
  }
  .download-btn{
      top: 20vh;
  }
  .home{
      background-position: bottom left, center;
      background-size: auto 80%, 100% 100%;
  }
  .word-box{
      left: 20vw;
      top: 35vh;
  }
  .spacer{
      height: 14vh;
      min-height: 100px;
  }
  .project{
      height: 330px;
      width: 430px;
  }
  .project-name{
      height: 50px;
      width: 360px;
      font-size: 30px;
  }
  .project-pic{
      height: 200px;
      width: 360px;
  }
  .project-button-list{
      width: 360px;
  }
  .project-button{
      height: 30px;
      width: 180px;
      font-size: 15px;
  }
  .tech-used{
      height: 200px;
      width: 360px;
      font-size: 15px;
  }
  .title-hide{
      width: 0;
      opacity: 0;
      -webkit-transition: width 0.5s, opacity 0s 0s;
  }
  .paragraph-show{
      width: 60vw;
      opacity: 1;
      -webkit-transition: width 0.5s, opacity 0.5s 0.5s;
  }
  .image{
      width: 20vw;
      min-width: 160px;
      max-width: 240px;
  }
  .skill-single:hover{
      background-size: auto 0%;
  }
  .skill-name:hover{
      background: rgba(0,0,0,0.3);
      font-size: 15px;
  }
  .contact-form{
    width: 450px;
  }
}

@media (min-width: 900px){
  .home-box{
      margin-right: 30vw;
  }
  .word-box{
      height: 250px;
      width: 400px;
  }
  .word-box h2{
      font-size: 54px;
  }
  .word-box p{
      font-size: 30px;
  }
  #latest-project{
      margin-top: 20px;
      height: 350px;
      width: 430px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background-image: url('pics/chalkborder.jpg');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
  }
  #latest-name{
      height: 50px;
      width: 360px;
      font-size: 30px;
  }
  #latest-pic{
      height: 200px;
      width: 360px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: rgba(0,0,0,0.7);
      cursor: pointer;
      box-sizing: border-box;
      position: unset;
  }
  .resume-content{
      width: 70%;
  }
  .close-btn{
      left: 20vw;
  }
  .contact-form{
    width: 840px;
  }
  .info{
    flex-direction: row;
  }
  .info-title{
      font-size: 20px;
      width: 50vw;
  }
  .info-list{
      width: 50vw;
  }
  .info-single{
      height: 30px;
      width: 30px;
  }
  .info-single a{
      font-size: 30px;
  }
  .envelope i{
      font-size: 26px;
  }
}

@media (min-width: 1200px){
  #p5{
      height: 20vh;
      min-height: 160px;
  }
  #p5 .paragraph-single{
      height: 20vh;
      min-height: 160px;
  }
  .img-5{
      height: 20vh;
      min-height: 160px;
  }
}

@media (min-width: 1357px){
  .undone-project{
      display: flex;
  }
}